import type { StepsFragment } from '~/model/api.ts'
import { buttonVariants } from '@rouvydev/web-components'
import { cn } from '~/utils/misc.ts'
import { Image } from '~/components/image.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'

export function prepareStepsProps(data: StepsFragment) {
  return {
    id: data.id,
    title: data.stepsTitle,
    button: {
      text: data.stepsButton?.text,
      link: data.stepsButton?.link,
      type: data.stepsButton?.buttonType,
      ctaEvent: data.stepsButton?.ctaEvent,
      style: data.stepsButton?.buttonStyle,
    },
    steps: data.step?.map(step => ({
      title: step?.title,
      description: step?.description,
      image: step?.image,
      alternativeImage: step?.alternativeImage,
    })),
    anchor: data.stepsAnchor,
  }
}

type Props = ReturnType<typeof prepareStepsProps>

export function Steps(props: Props) {
  const { title, button, steps } = props
  return (
    <div id={props?.anchor ?? undefined}>
      <div className="py-20">
        <div className="container">
          <h3 className="mb-8 text-center font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 xl:text-display-48">
            {title}
          </h3>
          {steps?.length && (
            <div className="grid grid-cols-1 gap-x-8 gap-y-12 lg:grid-cols-3">
              {steps.map((step, index) => (
                <div key={index}>
                  {step.image?.data?.attributes?.url && (
                    <div className="mx-auto  flex items-center justify-center p-6 lg:p-4">
                      <Image
                        src={step.image.data.attributes.url}
                        alt={step.image?.data.attributes.alternativeText ?? ''}
                        width={step.image?.data.attributes.width ?? 200}
                        height={step.image?.data.attributes.height ?? 200}
                        formats={step.image?.data.attributes.formats}
                        className="aspect-square h-[200px] object-contain"
                      />
                    </div>
                  )}
                  <div className="mx-auto max-w-md space-y-4">
                    <div className="relative">
                      <span
                        className={cn(
                          'absolute top-1/2 hidden h-0.5 w-full bg-global-deep-indigo-700 lg:block',
                          index === 0 && 'left-1/2',
                          index === steps.length - 1 && 'right-1/2',
                        )}
                      />
                      <div className="relative z-10 mx-auto grid h-12 w-12 place-items-center rounded-full bg-background-subtle text-body-16 text-text-default xl:text-body-20">
                        {index + 1}
                      </div>
                    </div>
                    <h4 className="text-center text-body-16 font-semibold text-text-default xl:text-body-20">
                      {step.title}
                    </h4>
                    <p className="text-center text-body-12 font-semibold text-text-muted xl:text-body-16">
                      {step.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {button?.link && (
            <p className="mt-16 text-center">
              <NormalizedLink
                className={cn(
                  buttonVariants({
                    variant: button.style ?? 'primary',
                  }),
                  button.style,
                )}
                to={button.link}
              >
                {button.text}
              </NormalizedLink>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
