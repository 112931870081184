import type { ChecklistFragment } from '~/model/api.ts'
import { Checklist as ChecklistBlock } from '@rouvydev/web-components/cms-blocks'

export function prepareChecklistProps(data: ChecklistFragment) {
  return {
    id: data.id,
    title: data.checklistTitle,
    items: data.checklistItems
      .split('\n')
      .map(item => item.trim())
      .filter(item => item.length > 0),
    anchor: data.checklistAnchor,
  }
}

type Props = ReturnType<typeof prepareChecklistProps>

export function Checklist(props: Props) {
  const { title, items } = props

  return (
    <div id={props?.anchor ?? undefined}>
      <div className="container">
        <ChecklistBlock items={items} title={title} />
      </div>
    </div>
  )
}
