import type { ChallengesFragment } from '~/model/api.ts'
import { ChallengeCard } from '~/components/collections/challenge-card.tsx'
import { cn } from '~/utils/misc.ts'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/carousel.tsx'

function prepareChallengesProps(data: ChallengesFragment) {
  return {
    challenges: data.challenges?.data,
    title: data.challengesTitle,
    anchor: data.challengesAnchor,
    expand: data.expand,
  }
}

type Props = ReturnType<typeof prepareChallengesProps>

function Challenges({ title, challenges, anchor, expand }: Props) {
  return challenges?.length ? (
    <div className="relative bg-global-dark-ink-blue-200">
      <div id={anchor ?? undefined}>
        <div className="flex flex-col gap-20 py-20">
          <h3 className="block-subtitle container">{title}</h3>
          <Carousel
            className="px-4"
            opts={{
              dragFree: true,
            }}
          >
            <CarouselContent>
              {challenges.map((challenge, index) => (
                <CarouselItem
                  key={index}
                  className={cn('basis-auto pl-8', index === 0 && 'pl-0')}
                >
                  {challenge.id && challenge.attributes?.title && (
                    <ChallengeCard
                      key={challenge.id}
                      id={challenge.id}
                      challengeId={challenge.attributes.challengeId}
                      experience={challenge.attributes.experience}
                      startDateTime={challenge.attributes.startDateTime}
                      endDateTime={challenge.attributes.endDateTime}
                      title={challenge.attributes?.title}
                      registeredCount={challenge.attributes.registeredCount}
                      description={
                        challenge.attributes?.description ?? undefined
                      }
                      logoOriginal={
                        challenge.attributes?.logoOriginal ?? undefined
                      }
                      expand={Boolean(expand)}
                    />
                  )}
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </div>
      </div>
    </div>
  ) : null
}

export { Challenges, prepareChallengesProps }
