import type { YoutubeVideoFragment } from '~/model/api.ts'
import { ReactPlayer } from '~/components/react-player.client.tsx'
import { useEffect, useState } from 'react'
import { Spinner } from '~/components/spinner.tsx'
import { cn } from '~/utils/misc.ts'

export function prepareYoutubeProps(data: YoutubeVideoFragment) {
  return {
    id: data.id,
    title: data.youTubeTitle,
    caption: data.caption,
    cover: data.cover,
    embedCode: data.embedCode,
    anchor: data.youTubeAnchor,
  }
}

type Props = ReturnType<typeof prepareYoutubeProps>

export function Youtube(props: Props) {
  const { title, embedCode, caption, anchor } = props
  const [renderPlayer, setRenderPlayer] = useState(false)
  const [showPlayer, setShowPlayer] = useState(false)

  useEffect(() => {
    setRenderPlayer(true)
  }, [])

  function onReady() {
    setShowPlayer(true)
  }

  return (
    <div id={anchor ?? undefined}>
      <div className="py-20">
        <div className="container">
          <div className="flex flex-col items-center">
            <h3 className="mb-10 font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 xl:text-display-48">
              {title}
            </h3>
            <div className="relative aspect-video w-full">
              {renderPlayer && (
                <div
                  className={cn(
                    'absolute z-20 h-full w-full transition-opacity duration-500',
                    showPlayer ? 'opacity-100' : 'opacity-0',
                  )}
                >
                  <ReactPlayer
                    url={`https://www.youtube.com/watch?v=${embedCode}`}
                    height="100%"
                    width="100%"
                    onReady={onReady}
                    className="border border-global-crimson-red-100"
                  />
                </div>
              )}
              <div
                className={cn(
                  'absolute z-10 grid h-full w-full place-items-center border border-border-secondary-subtle transition-opacity duration-500',
                  showPlayer ? 'opacity-0' : 'opacity-100',
                )}
              >
                <Spinner />
              </div>
            </div>
            <p className="mt-6 text-body-16 font-bold text-text-muted">
              {caption}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
