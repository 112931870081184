import type { ComponentSharedCard, HighlightsFragment } from '~/model/api.ts'
import { Enum_Componentsharedhighlights_Layout } from '~/model/api.ts'
import { buttonVariants } from '@rouvydev/web-components'
import { cn } from '~/utils/misc.ts'
import type { RenderableTreeNodes } from '@markdoc/markdoc'
import { Image } from '~/components/image.tsx'
import { Markdown } from '~/components/markdown.tsx'
import { Cols } from '~/components/cols.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'

type TransformedContentCard = {
  transformedContentText: RenderableTreeNodes
} & ComponentSharedCard

export function prepareHighlightsProps(data: HighlightsFragment) {
  return {
    id: data.id,
    highlightsAnchor: data.highlightsAnchor,
    highlight: data.Highlight?.length
      ? data.Highlight.map(h => {
          const transformedItem = h as TransformedContentCard

          return {
            button: h?.button,
            image: h?.contentImage,
            text: transformedItem.transformedContentText,
            title: h?.contentTitle,
          }
        })
      : [],
    highlightsDisplayFor: data.highlightsDisplayFor,
    highlightsTitle: data.highlightsTitle,
    layout: data.Layout,
  }
}

type Props = ReturnType<typeof prepareHighlightsProps>

const layoutIndexMap: Record<Enum_Componentsharedhighlights_Layout, number> = {
  [Enum_Componentsharedhighlights_Layout.AlternateLeft]: 2,
  [Enum_Componentsharedhighlights_Layout.AlternateRight]: 1,
  [Enum_Componentsharedhighlights_Layout.Left]: -1,
  [Enum_Componentsharedhighlights_Layout.Right]: -2,
}

export function Highlights(props: Props) {
  const { highlight, highlightsTitle, layout, highlightsAnchor } = props
  const layoutIndex = (layout && layoutIndexMap[layout]) ?? 1

  if (highlight.length === 0) {
    return null
  }

  function imgClassName(index: number) {
    return layoutIndex > 0
      ? (index + layoutIndex) % 2 === 0
        ? 'md:order-1'
        : 'md:order-10'
      : layoutIndex === -1
        ? 'md:order-1'
        : 'md:order-3'
  }

  function contentClassName(index: number) {
    return index % 2 === 1 ? 'md:order-1' : 'md:order-2'
  }

  return (
    <div>
      <div id={highlightsAnchor ?? undefined}>
        {highlightsTitle && (
          <h3 className="block-heading pt-20 text-center xl:pt-24">
            {highlightsTitle}
          </h3>
        )}
        <div className="divide-y divide-border-secondary-subtle">
          {highlight.map((h, index) => (
            <div key={index}>
              <div className="container py-20 md:py-10">
                <div
                  className={cn(
                    'flex flex-col items-center gap-10 lg:flex-row xl:gap-8',
                    imgClassName(index),
                    'order-2',
                  )}
                >
                  <Cols size="7" className={cn(imgClassName(index), 'order-2')}>
                    {h.image?.data?.attributes?.url && (
                      <Image
                        src={h.image.data.attributes.url}
                        alt={h.image.data.attributes.alternativeText ?? ''}
                        width={h.image?.data.attributes.width ?? 750}
                        height={h.image?.data.attributes.height ?? 750}
                        formats={h.image?.data.attributes.formats}
                        className="block aspect-square object-contain"
                      />
                    )}
                  </Cols>
                  <Cols
                    size="5"
                    className={cn(
                      contentClassName(index),
                      'order-1 flex flex-col items-center gap-4 py-6 xl:items-start xl:gap-6 xl:py-0',
                    )}
                  >
                    <h4 className="text-center font-display text-body-32 font-extrabold uppercase italic text-text-default lg:text-left xl:text-display-48">
                      {h.title}
                    </h4>
                    <div className="block-description text-center lg:text-left">
                      <Markdown content={h.text} />
                    </div>
                    {h.button?.link && (
                      <NormalizedLink
                        to={h.button.link}
                        className={buttonVariants({
                          variant: h.button.buttonStyle ?? 'alternative',
                        })}
                      >
                        {h.button.text}
                      </NormalizedLink>
                    )}
                  </Cols>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
