import { ResizeObserver } from '@juggle/resize-observer'
import type { MutableRefObject } from 'react'
import { useEffect, useRef, useState } from 'react'

type Props = {
  height?: number
  width?: number
  marginLeft: number
  marginRight: number
  marginTop: number
  marginBottom: number
  boundedHeight?: number
  boundedWidth?: number
}

export const useChartDimensions = (
  passedSettings: Props,
): [MutableRefObject<HTMLDivElement | null>, Props] => {
  const ref = useRef<HTMLDivElement | null>(null)
  const dimensions = combineChartDimensions(passedSettings)

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect((): any => {
    if (dimensions.width && dimensions.height) return [ref, dimensions]

    const element = ref.current as unknown as Element

    const resizeObserver = new ResizeObserver(entries => {
      if (!Array.isArray(entries)) return
      if (!entries.length) return

      const entry = entries[0]

      if (width != entry.contentRect.width) setWidth(entry.contentRect.width)
      if (height != entry.contentRect.height)
        setHeight(entry.contentRect.height)
    })
    resizeObserver.observe(element)

    return () => resizeObserver.unobserve(element)
  }, [dimensions, height, width])

  const newSettings = combineChartDimensions({
    ...dimensions,
    width: dimensions.width || width,
    height: dimensions.height || height,
  })

  return [ref, newSettings]
}

const combineChartDimensions = (dimensions: Props) => {
  const parsedDimensions = {
    ...dimensions,
    marginTop: dimensions.marginTop || 0,
    marginRight: dimensions.marginRight || 0,
    marginBottom: dimensions.marginBottom || 0,
    marginLeft: dimensions.marginLeft || 0,
    width: dimensions.width || 0,
    height: dimensions.height || 0,
  }
  return {
    ...parsedDimensions,
    boundedHeight: Math.max(
      parsedDimensions.height -
        parsedDimensions.marginTop -
        parsedDimensions.marginBottom,
      0,
    ),
    boundedWidth: Math.max(
      parsedDimensions.width -
        parsedDimensions.marginLeft -
        parsedDimensions.marginRight,
      0,
    ),
  }
}
