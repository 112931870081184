import type { VideoFragment } from '~/model/api.ts'
import type { CSSProperties } from 'react'
import { useEffect, useRef, useState } from 'react'
import { cn, useIsVisible } from '~/utils/misc.ts'
import { Image } from '~/components/image.tsx'
import { ReactPlayer } from '~/components/react-player.client.tsx'

type PlayerProgress = {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

type Props = {
  video: VideoFragment
  className?: string
  style?: CSSProperties
}

export function Video({ video, className, style }: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useIsVisible(ref)

  const [renderPlayer, setRenderPlayer] = useState(false)
  const [videoReady, setVideoReady] = useState(false)
  const [showPlayer, setShowPlayer] = useState(false)
  const [play, setPlay] = useState(video.autoPlay)
  const [playerReady, setPlayerReady] = useState(false)

  // Render player on load
  useEffect(() => {
    setRenderPlayer(isVisible)
  }, [isVisible])

  // Show player and start video if autoPlay
  useEffect(() => {
    if (video.showVideo === 'on_load' && videoReady && isVisible) {
      setShowPlayer(true)
      setPlay(!video.showControls || video.autoPlay)
    }
  }, [
    video.showVideo,
    videoReady,
    video.autoPlay,
    isVisible,
    video.showControls,
  ])

  // Start or stop video based on visibility
  useEffect(() => {
    if (showPlayer && isVisible) {
      setPlay(!video.showControls || video.autoPlay)
    }
  }, [isVisible, showPlayer, video.autoPlay, video.showControls])

  useEffect(() => {
    setVideoReady(playerReady)
  }, [playerReady])

  function onReady() {
    setPlayerReady(true)
  }

  // Show player on hover and start video if autoPlay
  function onHover() {
    if (video.showVideo === 'on_hover' && videoReady && isVisible) {
      setShowPlayer(true)
      setPlay(video.autoPlay)
    }
  }

  // Show player on click and start video if autoPlay
  function onClick() {
    if (video.showVideo === 'on_click' && videoReady && isVisible) {
      setShowPlayer(true)
      setPlay(!video.showControls || video.autoPlay)
    }
  }

  function onProgress(state: PlayerProgress) {
    if (state.played === 1 && !video.loop) {
      setPlay(false)
      setShowPlayer(false)
    }
  }

  return (
    <div
      className={className}
      ref={ref}
      onMouseOver={onHover}
      onClick={onClick}
      style={style}
    >
      <div className="relative aspect-video min-h-max min-w-max">
        {renderPlayer && (
          <div
            className={cn(
              'absolute bottom-0 left-0 top-0 z-30 h-full w-full transition-opacity duration-1000 lg:right-0',
              videoReady && showPlayer ? 'opacity-100' : 'opacity-0',
            )}
          >
            {/* @ts-ignore */}
            <ReactPlayer
              url={video.video.data?.attributes?.url ?? ''}
              controls={video.showControls}
              muted={video.muted}
              loop={video.loop}
              playing={play}
              onReady={onReady}
              onProgress={onProgress}
              width="100%"
              height="100%"
              className="child:object-cover child:object-center"
              playsinline={true}
              stopOnUnmount
            />
          </div>
        )}
        <div
          className={cn(
            'absolute bottom-0 left-0 right-0 top-0 z-20 h-full w-full transition-opacity duration-1000',
            videoReady && showPlayer ? 'opacity-0' : 'opacity-100',
          )}
        >
          <Image
            src={video.thumbnail?.data?.attributes?.url}
            alt={video.thumbnail?.data?.attributes?.alternativeText ?? ''}
            skipSmallVariants
            loading="eager"
            className="absolute top-0 min-h-full min-w-full object-cover object-right"
          />
        </div>
      </div>
    </div>
  )
}
