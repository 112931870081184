import type { WorkoutFragment } from '~/model/api.ts'
import { CollectionCard } from './collection-card.tsx'
import { Icon } from '~/components/icon.tsx'
import { IconValue } from '~/components/ui/icon-value.tsx'
import { formatDuration } from '~/utils/date.ts'
import { Badge } from '~/components/ui/badge.tsx'
import { WorkoutInterval } from './workout-interval.tsx'
import { useTranslation } from 'react-i18next'
import { cn } from '~/utils/misc.ts'

export function WorkoutCard({
  id,
  name,
  segments,
  duration,
  description,
  tss,
  coinsForCompletion,
  intensityFactor,
  expand,
  className,
  difficulty,
  trainingEffect,
}: WorkoutFragment & {
  expand?: boolean
  className?: string
}) {
  const { t } = useTranslation(['collections'])
  const trainingEffectLabel = useTrainingEffect(trainingEffect)
  const url = `${ENV.RIDERS_PORTAL_URL}/workouts/${id}`

  var difficultyColor = null
  var difficultyLabel = null
  switch (difficulty) {
    case 'easy':
      difficultyColor = 'text-global-eucalyptus-700'
      difficultyLabel = t('workout_difficulty_easy')
      break
    case 'medium':
      difficultyColor = 'text-global-jeans-blue-700'
      difficultyLabel = t('workout_difficulty_medium')
      break
    case 'hard':
      difficultyColor = 'text-global-electric-violet-700'
      difficultyLabel = t('workout_difficulty_hard')
      break
  }

  const badges = (
    <>
      {trainingEffectLabel && (
        <Badge color="darkBlue" className="gap-1 uppercase">
          {trainingEffectLabel}
        </Badge>
      )}
      {difficultyLabel && (
        <Badge
          color="darkBlue"
          className={cn('gap-1 uppercase', difficultyColor)}
        >
          {difficultyLabel}
        </Badge>
      )}
    </>
  )

  const properties = (
    <>
      <IconValue
        icon={<Icon name="stopwatch" className="h-4 w-4" />}
        value={formatDuration(duration)}
      />
      {!!coinsForCompletion && (
        <IconValue
          icon={<Icon name="coin" className="h-4 w-4" />}
          value={coinsForCompletion.toString()}
          className="text-global-jonquil-yellow-700"
        />
      )}
      {!!tss && (
        <IconValue
          icon={<Icon name="tss" className="h-4 w-4" />}
          value={tss.toString()}
        />
      )}
      {!!intensityFactor && (
        <IconValue
          icon={<Icon name="workout" className="h-4 w-4" />}
          value={intensityFactor.toString()}
        />
      )}
    </>
  )

  return (
    <CollectionCard
      title={name ?? ''}
      link={url}
      subtitle={
        <div className="relative inset-0">
          <div className="absolute z-0 -ml-6 -mt-4 w-[304px] blur-xl">
            <WorkoutInterval segments={segments} isSmall={true} />
          </div>
          <div className="z-20 -mx-6">
            <WorkoutInterval segments={segments} isSmall={true} />
          </div>
        </div>
      }
      badges={badges}
      properties={properties}
      expand={expand}
      expandedTitle={name ?? ''}
      expandedDescription={description ?? ''}
      className={className}
    />
  )
}

function useTrainingEffect(tag?: string | null) {
  const { t } = useTranslation(['collections'])
  switch (tag) {
    case 'ftp-test':
      return t('workout_training_effect_ftp_test')
    case 'vo2-max':
      return t('workout_training_effect_vo2_max')
    case 'climbing':
      return t('workout_training_effect_climbing')
    case 'sprinting':
      return t('workout_training_effect_sprinting')
    case 'cadence':
      return t('workout_training_effect_cadence')
    case 'threshold':
      return t('workout_training_effect_threshold')
    case 'recovery':
      return t('workout_training_effect_recovery')
    case 'anaerobic':
      return t('workout_training_effect_anaerobic')
    case 'strength':
      return t('workout_training_effect_strength')
    case 'race':
      return t('workout_training_effect_race')
    case 'sweet-spot-tempo':
      return t('workout_training_effect_sweet_spot_tempo')
    case 'time-trial':
      return t('workout_training_effect_time_trial')
    case 'endurance':
      return t('workout_training_effect_endurance')
    default:
      return null
  }
}
