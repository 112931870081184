import { cn } from '~/utils/misc.ts'

type Props = {
  rating: number
  displayRating: boolean
  count?: number
  color?: Color
}

type ColorRecord = {
  stroke: string
  fill: string
  text: string
}

export type Color = 'yellow' | 'purple'

const colors: Record<Color, ColorRecord> = {
  yellow: {
    stroke: 'stroke-global-jonquil-yellow-700',
    fill: 'fill-global-jonquil-yellow-700',
    text: 'text-foreground-primary',
  },
  purple: {
    stroke: 'stroke-global-electric-indigo-700',
    fill: 'fill-global-electric-indigo-700',
    text: 'text-foreground-secondary',
  },
}

export function Rating({ rating, color, count, displayRating }: Props) {
  const colorStyle = color ? colors[color] : colors['yellow']

  return (
    <div className="flex items-end gap-1">
      {[...Array(5).keys()].map(i => {
        return (
          <div key={i}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              className={cn(
                'h-5 w-5',
                colorStyle.stroke,
                rating < i + 1 ? 'fill-none' : colorStyle.fill,
              )}
            >
              <path
                strokeLinecap="square"
                strokeLinejoin="round"
                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
              />
            </svg>
          </div>
        )
      })}
      {displayRating && (
        <span
          className={cn('pl-2 text-body-16 leading-[20px]', colorStyle.text)}
        >
          {rating}
        </span>
      )}
      {count != null && (
        <span className="pl-1 text-body-12 font-medium leading-[17px]">
          {`(${count}x)`}
        </span>
      )}
    </div>
  )
}
