import { useTranslation } from 'react-i18next'
import { cn } from '~/utils/misc.ts'
import { motion } from 'framer-motion'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { intlFormatDistance, isAfter, isBefore } from 'date-fns'
import { Icon } from '~/components/icon.tsx'
import { IconValue } from '~/components/ui/icon-value.tsx'
import { Image } from '~/components/image.tsx'
import { buttonVariants } from '@rouvydev/web-components'

// @todo for some reason this icon doesn't want to be displayed so the temporary solution is to use the component
function Experience({ className }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.642 17.67l5.411 4.016c.281.209.614.313.947.313h2l-6-4.33V6.5l6-4.506h-2c-.333 0-.666.104-.947.313L4.642 6.324C4.238 6.624 4 7.097 4 7.6v8.794c0 .503.238.976.642 1.275z"
        fill="url(#paint0_linear_727_1849)"
      />
      <g filter="url(#filter0_i_727_1849)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.947 2.307a1.588 1.588 0 00-1.894 0L6.642 6.324C6.238 6.624 6 7.097 6 7.6v8.794c0 .503.238.976.642 1.275l5.411 4.017a1.588 1.588 0 001.894 0l5.411-4.017c.404-.3.642-.773.642-1.275V7.6c0-.503-.238-.976-.642-1.276l-5.411-4.017zm-.939 13.463l-1.44-3.96L12.9 8.412h-1.548l-.667 2.265-.61-2.265h-1.58l1.292 3.451-1.383 3.907h1.548l.716-2.689.758 2.69h1.581zm4.093-6.734c-.4-.416-.974-.624-1.721-.624h-1.738v7.358h1.49v-2.382h.412c.67 0 1.197-.215 1.582-.646.384-.437.576-1.07.576-1.895 0-.798-.2-1.401-.601-1.81zm-1.112 2.71c-.12.184-.316.276-.585.276h-.271V9.756h.272c.274 0 .472.092.593.276.12.176.18.448.18.815 0 .416-.063.716-.189.9z"
          fill="url(#paint1_linear_727_1849)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_727_1849"
          x={6}
          y={1.99414}
          width={14}
          height={20.0049}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={0.25} dy={0.25} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend in2="shape" result="effect1_innerShadow_727_1849" />
        </filter>
        <linearGradient
          id="paint0_linear_727_1849"
          x1={8.50001}
          y1={1.99414}
          x2={8.50001}
          y2={21.9992}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#34D49C" />
          <stop offset={0.232196} stopColor="#34D49C" />
          <stop offset={0.283885} stopColor="#24936C" />
          <stop offset={0.742758} stopColor="#24936C" />
          <stop offset={0.776515} stopColor="#1C7254" />
          <stop offset={1} stopColor="#1C7254" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_727_1849"
          x1={13}
          y1={2.09556}
          x2={13}
          y2={22.1007}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53DBAB" />
          <stop offset={1} stopColor="#24936C" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export function ChallengeCard({
  logoOriginal,
  id,
  challengeId,
  className,
  title,
  startDateTime,
  endDateTime,
  registeredCount,
  experience,
  expand,
  description,
}: {
  id: string
  challengeId: string
  logoOriginal?: string
  className?: string
  title: string
  startDateTime: string
  endDateTime: string
  registeredCount: number
  experience: number
  expand?: boolean
  description?: string
}) {
  const { t } = useTranslation()
  const url = `${ENV.RIDERS_PORTAL_URL}/challenges/${challengeId}`

  const currentDate = new Date()
  const startDate = new Date(startDateTime)
  const endDate = new Date(endDateTime ?? '')
  const isUpcoming = isBefore(currentDate, startDate)
  const isPast = isAfter(currentDate, endDate)
  const targetDate = new Date(isUpcoming ? startDate : endDate)

  const targetDateDistance = `${
    isUpcoming ? t('Starts') : isPast ? t('Ended') : t('Ends')
  } ${intlFormatDistance(targetDate, currentDate)}`

  return (
    <div className="flex flex-col bg-global-dark-ink-blue-200 lg:flex-row">
      <article
        className={cn(
          'relative isolate flex min-h-[400px] w-[304px] min-w-[304px] flex-col justify-end overflow-hidden  px-6 pb-11 text-global-white',
          className,
        )}
      >
        <div>
          {logoOriginal && (
            <Image
              src={logoOriginal}
              alt={title}
              width={50}
              height={50}
              className="absolute inset-0 -top-[30%] left-[50%] -z-10 h-[95%] w-[150%] max-w-none -translate-x-1/2 object-cover opacity-[50%] blur-md"
            />
          )}
          {logoOriginal && (
            <Image
              src={logoOriginal}
              alt={title}
              width={0}
              height={148}
              className="absolute left-[50%] top-10 max-h-[148px] -translate-x-1/2"
            />
          )}
          <div className="card-gradient absolute inset-0 -z-10"></div>
        </div>

        <div className="mb-4 mt-12">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.1 }}
          >
            <p className="text-body-12">{targetDateDistance}</p>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
        >
          {url && (
            <NormalizedLink
              to={url}
              className="mb-5 block font-display text-display-28 uppercase italic text-text-default"
            >
              {title}
            </NormalizedLink>
          )}
          {!url && (
            <h3 className="mb-5 font-display text-display-28 uppercase italic">
              {title}
            </h3>
          )}
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative"
        >
          <div className="flex">
            <IconValue
              icon={<Icon name="users" className="h-4 w-4" />}
              value={registeredCount ? registeredCount.toLocaleString() : '0'}
            />
            <IconValue
              icon={<Experience className="h-[20px] w-[20px]" />}
              value={`+${experience ? experience.toLocaleString() : '0'}`}
              className="ml-5 text-global-eucalyptus-700"
            />
          </div>
        </motion.div>
      </article>
      {expand && (
        <div className="flex h-80 w-[304px] flex-col justify-between gap-4 bg-global-dark-ink-blue-300 px-5 py-8 text-left lg:h-auto lg:w-[42rem] lg:gap-6 lg:p-8">
          <div className="flex flex-col gap-4 lg:gap-6">
            <h4 className="line-clamp-2 font-display text-display-28 font-extrabold uppercase italic leading-8 text-text-default lg:text-body-32">
              {title}
            </h4>
            <p className="line-clamp-6 h-36 overflow-hidden text-body-14 font-semibold text-text-muted lg:h-40 lg:text-body-20">
              {description}
            </p>
          </div>
          <div>
            <NormalizedLink
              to={url}
              className={cn(
                buttonVariants({
                  variant: 'alternative',
                  size: 'small',
                }),
              )}
            >
              {t('more_info')}
            </NormalizedLink>
          </div>
        </div>
      )}
    </div>
  )
}
