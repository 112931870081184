import { extent } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import { select } from 'd3-selection'
import { area, curveMonotoneX, line } from 'd3-shape'
import { createRef, useCallback, useEffect, useMemo } from 'react'
import { theme } from '@rouvydev/web-components/utils'

type Props = {
  geometry: PointRoute[]
  variant?: 'base' | 'warning'
  lineOnly?: boolean
  height?: number
  width?: number
  strokeWidth?: number
}

type PointRoute = {
  distance: number
  altitude: number
}

export function RouteProfile({
  geometry,
  variant = 'base',
  lineOnly = false,
  height = 50,
  width = 200,
  strokeWidth = 1,
}: Props) {
  const node = createRef<SVGPathElement>()

  const variants = useMemo(
    () => ({
      base: {
        color: theme.colors['text-secondary'],
      },
      warning: {
        color: theme.colors['background-negative'],
      },
    }),
    [],
  )

  const coords: [number, number][] = geometry.map(item => [
    item.distance,
    item.altitude,
  ])

  const valuesX = coords.map(i => i[0])
  const valuesY = coords.map(i => i[1])

  const domainX = extent(valuesX) as [number, number]
  const domainY = extent(valuesY) as [number, number]

  const del =
    Math.round((domainY[1] - domainY[0]) * 0.3) ||
    Math.round(domainY[0] * 0.3) ||
    10
  domainY[0] -= del
  domainY[1] += 10

  const scaleX = scaleLinear().range([0, width]).domain(domainX)
  const scaleY = scaleLinear().range([height, 0]).domain(domainY)

  const chartArea = area()
    .curve(curveMonotoneX)
    .x((d): number => scaleX(d[0] || 0) || 0)
    .y0(height)
    .y1((d): number => scaleY(d[1] || 0) || 0)

  const chartLine = line()
    .curve(curveMonotoneX)
    .x((d): number => scaleX(d[0] || 0) || 0)
    .y((d): number => scaleY(d[1] || 0) || 0)

  const drawArea = useCallback(() => {
    select(node.current).selectAll('*').remove()

    const selection = select(node.current).datum(coords)
    if (!lineOnly) {
      selection
        .append('path')
        .attr('class', 'area')
        .attr('d', chartArea(coords))
        .attr('fill', `url(#${variant})`)
    }

    selection
      .append('path')
      .attr('d', chartLine(coords))
      .attr('fill', 'none')
      .attr('stroke', variants[variant].color)
      .attr('stroke-width', strokeWidth)
      .attr('closed', 'false')
  }, [
    chartArea,
    chartLine,
    coords,
    lineOnly,
    node,
    variant,
    variants,
    strokeWidth,
  ])

  useEffect(() => {
    drawArea()
  }, [drawArea])

  return (
    <svg viewBox={`0 0 ${width} ${height}`} data-testid="route-profile">
      <defs>
        <linearGradient id="base" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop
            offset="0%"
            style={{ stopColor: variants[variant].color, stopOpacity: 0.3 }}
          />
          <stop
            offset="100%"
            style={{ stopColor: variants[variant].color, stopOpacity: 0 }}
          />
        </linearGradient>
      </defs>
      <g ref={node}></g>
    </svg>
  )
}
