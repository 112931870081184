import * as React from 'react'

import { cn } from '~/utils/misc.ts'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

const inputVariants = cva(
  'placeholder-text-text-muted date-icon-white block border-0 bg-background-canvas p-3 text-body-14 text-text-subtle outline-0 ring-1 ring-inset ring-border-secondary focus:ring-1 focus:ring-inset focus:ring-border-focus',
  {
    variants: {
      fullWidth: {
        true: 'w-full',
      },
    },
  },
)

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  fullWidth?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, fullWidth, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ className, fullWidth }))}
        ref={ref}
        {...props}
      />
    )
  },
)

Input.displayName = 'Input'

export { Input }
