import { NormalizedLink } from '~/components/normalized-link.tsx'
import type { ReactNode } from 'react'
import { ThumborUrlBuilder } from 'thumbor-url-builder-ts'
import { cn } from '~/utils/misc.ts'
import { buttonVariants } from '@rouvydev/web-components'
import { useTranslation } from 'react-i18next'

const thumbor = new ThumborUrlBuilder(ENV.THUMBOR_SECURITY, ENV.THUMBOR_URL)

type Props = {
  className?: string
  tag?: string
  title: string
  link: string
  expand?: boolean
  expandedTitle?: string
  expandedDescription?: string
  subtitle?: ReactNode
  bgImageUrl?: string
  geometry?: ReactNode
  badges?: ReactNode
  properties?: ReactNode
}

export function CollectionCard({
  className,
  tag,
  title,
  link,
  subtitle,
  bgImageUrl,
  geometry,
  badges,
  properties,
  expand = false,
  expandedTitle,
  expandedDescription,
}: Props) {
  bgImageUrl = bgImageUrl
    ? thumbor
        .setImagePath(encodeURIComponent(bgImageUrl))
        .resize(304, 296)
        .smartCrop(true)
        .buildUrl()
    : ''
  const { t } = useTranslation()

  return (
    <div className="flex flex-col bg-global-dark-ink-blue-200 lg:flex-row">
      <article
        data-testid="collection-card"
        className={cn(
          'flex h-[400px] min-h-[400px] w-[304px] min-w-[304px] flex-col overflow-hidden text-global-white',
          className,
        )}
      >
        <div
          className="flex h-[74%] flex-col justify-between bg-cover bg-top"
          style={{
            background: bgImageUrl
              ? `linear-gradient(0deg, #110728 0%, rgba(17, 7, 40, 0.00) 71.59%), url(${bgImageUrl}) lightgray 50% / cover no-repeat`
              : '',
          }}
        >
          <div className="flex flex-wrap justify-end gap-1 p-3">{badges}</div>

          <div className="flex flex-col gap-y-4 px-6 text-left">
            {tag && <span className="font-bold text-global-white">{tag}</span>}
            <div>{subtitle}</div>
            <NormalizedLink
              to={link}
              className="block font-display text-[1.75rem] font-extrabold uppercase italic leading-7"
            >
              {title}
            </NormalizedLink>
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div
            className="mt-3 flex flex-row gap-5 pl-6"
            data-testid="properties"
          >
            {properties}
          </div>
          {geometry && (
            <div className="relative max-h-[32px] md:block">{geometry}</div>
          )}
        </div>
      </article>
      {expand && (
        <div className="flex h-80 w-[304px] flex-col justify-between gap-4 bg-global-dark-ink-blue-300 px-5 py-8 text-left lg:h-auto lg:w-[42rem] lg:gap-6 lg:p-8">
          <div className="flex flex-col gap-4 lg:gap-6">
            <h4 className="line-clamp-2 font-display text-display-28 font-extrabold uppercase italic leading-8 text-text-default lg:text-body-32">
              {expandedTitle}
            </h4>
            <p className="h-36 overflow-hidden text-body-14 font-semibold text-text-muted lg:h-40 lg:text-body-20">
              <span className="line-clamp-5">{expandedDescription}</span>
            </p>
          </div>

          <NormalizedLink
            to={link}
            className={cn(
              buttonVariants({ variant: 'alternative', size: 'small' }),
              'w-fit',
            )}
          >
            {t('more_info')}
          </NormalizedLink>
        </div>
      )}
    </div>
  )
}
