import type { FaqFragment } from '~/model/api.ts'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/components/accordion.tsx'
import { Cols } from '~/components/cols.tsx'
import { StructuredData } from '~/components/structured-data.tsx'

function prepareFaqProps(data: FaqFragment) {
  return {
    id: data.id,
    question: data?.question
      ? data.question
          .filter(Boolean)
          .filter(
            (q): q is { id: string; questionText: string; answer: string } =>
              Boolean(q.questionText && q.answer),
          )
      : [],
    anchor: data?.faqAnchor,
  }
}

type Props = ReturnType<typeof prepareFaqProps>

function Faq(props: Props) {
  const { question } = props

  const structuredData: StructuredData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: question.map(q => ({
      '@type': 'Question',
      name: q.questionText,
      acceptedAnswer: {
        '@type': 'Answer',
        text: q.answer,
      },
    })),
  }

  return (
    <div>
      <div className="py-20 md:py-24" id={props?.anchor ?? undefined}>
        <div className="container">
          <Cols size="10">
            <h3 className="font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 xl:text-display-48">
              {'Faq'}
            </h3>
            <Accordion
              type="multiple"
              className="mt-8 w-full border-t border-t-border-secondary-subtle"
            >
              {question.map((q, index) => (
                <AccordionItem
                  className="py-8"
                  value={`item-${index}`}
                  key={index}
                >
                  <AccordionTrigger>{q.questionText}</AccordionTrigger>
                  <AccordionContent>{q.answer}</AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </Cols>
        </div>
        <StructuredData structuredData={structuredData} />
      </div>
    </div>
  )
}

export { prepareFaqProps, Faq }
