import { cn } from '~/utils/misc.ts'
import { Image } from '~/components/image.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { useTranslation } from 'react-i18next'
import { buttonVariants } from '@rouvydev/web-components'
import type { BlogPostListItemFragment } from '~/model/api.ts'

export function BlogPostCard({
  title,
  perex,
  slug,
  cover,
  expand,
}: BlogPostListItemFragment & { expand: boolean }) {
  const { t } = useTranslation(['blog'])

  return (
    <article
      className={cn(
        expand ? 'flex-col lg:flex-row' : 'flex-col',
        'flex bg-global-dark-ink-blue-200',
      )}
    >
      {expand ? (
        <Image
          src={cover?.data?.attributes?.url}
          alt={cover?.data?.attributes?.alternativeText ?? ''}
          formats={cover?.data?.attributes?.formats}
          width={304}
          height={400}
          className="h-[304px] min-h-[304px] w-[304px] min-w-[304px] object-cover object-top lg:h-[400px] lg:min-h-[400px]"
        />
      ) : (
        <Image
          src={cover?.data?.attributes?.url}
          alt={cover?.data?.attributes?.alternativeText ?? ''}
          formats={cover?.data?.attributes?.formats}
          width={304}
          height={304}
          className="h-[304px] min-h-[304px] w-[304px] min-w-[304px]"
        />
      )}
      <div
        className={cn(
          expand ? 'w-[304px] lg:w-[42rem]' : 'w-[304px]',
          'flex flex-col justify-between gap-4 bg-global-dark-ink-blue-300 px-5 py-8 text-left lg:h-[400px] lg:gap-6 lg:p-8',
        )}
      >
        <div className="flex flex-col gap-4 lg:gap-6">
          <h4 className="line-clamp-2 font-display text-display-28 font-extrabold uppercase italic leading-8 text-text-default lg:text-body-32">
            {title}
          </h4>
          <p className="line-clamp-5 text-body-14 font-semibold text-text-muted lg:text-body-20">
            {perex}
          </p>
        </div>

        <NormalizedLink
          className={cn(
            buttonVariants({
              size: 'medium',
              variant: 'alternative',
            }),
            'w-fit',
          )}
          to={`/blog/${slug}`}
        >
          {t('read_article_link')}
        </NormalizedLink>
      </div>
    </article>
  )
}
