import type { RoutesPageListItemFragment } from '~/model/api.ts'
import { CollectionCard } from './collection-card.tsx'
import { Rating } from '~/components/ui/rating.tsx'
import { RouteProfile } from './route-profile.tsx'
import { Icon } from '~/components/icon.tsx'
import { IconValue } from '~/components/ui/icon-value.tsx'
import { formatDuration } from '~/utils/date.ts'
import { convertMetersToKm } from '~/utils/distance.ts'
import { Badge } from '~/components/ui/badge.tsx'

export function RouteCard({
  id,
  name,
  geometry,
  rating,
  stravaLiveSegmentsCount,
  supportsAugmentedReality,
  maxVideoQuality,
  onlineCount,
  distanceInMeters,
  elevationInMeters,
  estimatedTime,
  videoPreview,
  description,
  expand,
}: RoutesPageListItemFragment & {
  expand?: boolean
}) {
  const url = `${ENV.RIDERS_PORTAL_URL}/route/${id}`

  const badges = (
    <>
      {stravaLiveSegmentsCount !== 0 && (
        <Badge color="stravaOrange">
          <Icon name="strava" className="h-4 w-4" />
        </Badge>
      )}
      {supportsAugmentedReality && <Badge color="black">{'AR'}</Badge>}
      {maxVideoQuality && <Badge color="black">{'2K'}</Badge>}

      {(onlineCount ?? 0) >= 0 && (
        <Badge className="gap-1">
          <Icon name="cyclist" className="h-4 w-4" />
          <div>{onlineCount}</div>
        </Badge>
      )}
    </>
  )

  const properties = (
    <>
      {distanceInMeters && (
        <IconValue
          icon={<Icon name="distance" fill="#fff" className="h-4 w-4" />}
          value={`${convertMetersToKm(distanceInMeters)} km`}
        />
      )}
      {elevationInMeters && (
        <IconValue
          icon={<Icon name="elevation" className="h-4 w-4" />}
          value={elevationInMeters + ' m'}
        />
      )}
      {estimatedTime && (
        <IconValue
          icon={<Icon name="eta" className="h-4 w-4" />}
          value={formatDuration(estimatedTime)}
        />
      )}
    </>
  )

  return (
    <CollectionCard
      title={name}
      link={url}
      subtitle={<Rating rating={rating} displayRating={false} />}
      geometry={
        <RouteProfile geometry={geometry} height={32} strokeWidth={3} />
      }
      badges={badges}
      properties={properties}
      bgImageUrl={videoPreview.thumbnailHiRes ?? undefined}
      expand={expand}
      expandedDescription={description ?? undefined}
      expandedTitle={name}
    />
  )
}
