import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/carousel.tsx'
import { cn } from '~/utils/misc.ts'
import type { EventInfo, EventsFragment } from '~/model/api.ts'
import { useTranslation } from 'react-i18next'
import { buttonVariants } from '@rouvydev/web-components'
import { EventInfoCard } from '~/components/collections/event-info-card'

function prepareEventsProps(data: EventsFragment) {
  return {
    title: data.eventsTitle,
    anchor: data.eventsAnchor,
    events: data.eventInfos?.data,
    expand: data.expand,
    button: data.button,
    eventInfos: data.eventInfos?.data,
  }
}
type Props = ReturnType<typeof prepareEventsProps>
function Events({ title, anchor, eventInfos, expand, button }: Props) {
  const { t } = useTranslation()

  return eventInfos?.length ? (
    <div className="relative bg-global-dark-ink-blue-200">
      <div id={anchor ?? undefined}>
        <div className="flex flex-col gap-20 py-20">
          <h3 className="block-subtitle container">{title}</h3>

          <Carousel
            className="px-4"
            opts={{
              dragFree: true,
            }}
          >
            <CarouselContent>
              {eventInfos.map((eventInfo, index) => (
                <CarouselItem
                  key={index}
                  className={cn('basis-auto pl-8', index === 0 && 'pl-0')}
                >
                  <EventInfoCard
                    key={eventInfo.attributes?.id}
                    {...(eventInfo.attributes as EventInfo)}
                    expand={expand === true}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
          {button?.link && (
            <div className="flex items-center justify-center">
              <a
                className={buttonVariants({
                  variant: 'alternative',
                })}
                href={button.link}
              >
                {button.text ? button.text : t('events:moreEvents')}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null
}

export { Events, prepareEventsProps }
