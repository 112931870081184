import type { ReactNode } from 'react'
import { cn } from '~/utils/misc.ts'

type Props = {
  icon: ReactNode
  value: string
  size?: Size
  className?: string
}

type IconValueProps = {
  text: string
  icon: string
}

export type Size = 'small' | 'medium'

const sizes: Record<Size, IconValueProps> = {
  small: {
    text: 'text-body-14',
    icon: 'mb-2 h-4',
  },
  medium: {
    text: 'md:text-lg text-body-14',
    icon: 'md:mb-3 md:h-5 mb-2.5 h-4',
  },
}

export function IconValue({ icon, value, className, size }: Props) {
  const elementSize = size ? sizes[size] : sizes['small']

  return (
    <div
      className={cn('flex flex-col items-center', elementSize.text, className)}
      data-testid="icon-value"
    >
      <div className={cn('w-auto items-center', elementSize.icon)}>{icon}</div>
      {value}
    </div>
  )
}
