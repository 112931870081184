import type { PlansSelectorFragment } from '~/model/api.ts'
import { PlansSelector as PlansSelectorBlock } from '@rouvydev/web-components/plans'
import {
  buttonVariants,
  SubtitledContentBlockWithCta,
} from '@rouvydev/web-components'
import { cn } from '~/utils/misc.ts'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { useTranslation } from 'react-i18next'
import type { AllPlansResult, Plan } from '~/utils/pricing.server.ts'
import { priceFormatRaw } from '~/utils/priceFormat.ts'
import { Cols } from '~/components/cols.tsx'

type TransformedPlansSelectorData = {
  plansData: AllPlansResult
} & PlansSelectorFragment

export function preparePlansSelectorProps(data: PlansSelectorFragment) {
  const { plansData } = data as TransformedPlansSelectorData

  const plans = plansData?.plans?.map((plan: Plan) => ({
    id: plan.id,
    planId: plan.item_id,
    price: plan.price,
    currency_code: plan.currency_code,
    trialDays: plan.trial_period,
    billingPeriod: plan.period_unit,
  }))

  return {
    id: data.id,
    title: data.plansSelectorTitle,
    subtitle: data.plansSelectorSubtitle,
    anchor: data.plansSelectorAnchor,
    button: data.button,
    plans,
  }
}

type Props = ReturnType<typeof preparePlansSelectorProps>

/**
 * t('item_title_rouvy_single')
 * t('item_title_rouvy_duo')
 * t('item_title_rouvy_group')
 * t('item_accounts_rouvy_single')
 * t('item_accounts_rouvy_duo')
 * t('item_accounts_rouvy_group')
 */
export function PlansSelector(props: Props) {
  const { title, subtitle, button, plans } = props
  const { t } = useTranslation('pricing')

  const items = plans?.map(plan => ({
    id: plan.id,
    planId: plan.planId,
    billingPeriod: plan.billingPeriod,
    price:
      plan.billingPeriod === 'year'
        ? priceFormatRaw(plan.price / 12, plan.currency_code)
        : priceFormatRaw(plan.price, plan.currency_code),
    title: t(`item_title_${plan.planId}`),
    content: [
      t(`item_accounts_${plan.planId}`),
      t('item_trial_days', { trialDays: plan.trialDays }),
    ],
  }))

  return (
    <div id={props?.anchor ?? undefined}>
      <div className="container">
        <Cols size="10">
          <SubtitledContentBlockWithCta
            title={title}
            subtitle={subtitle ?? undefined}
            cta={
              button && (
                <NormalizedLink
                  to={button.link}
                  className={cn(
                    buttonVariants({
                      variant: button.buttonStyle ?? 'primary',
                    }),
                    button.buttonStyle,
                  )}
                >
                  {button.text}
                </NormalizedLink>
              )
            }
          >
            <PlansSelectorBlock
              interactive={false}
              active={null}
              defaultPlanId=""
              periods={['year', 'month']}
              initialPeriod="year"
              items={items ?? []}
              strings={{
                period_year: t('period_year'),
                year_free_months_claim: t('year_free_months_claim'),
                period_month: t('period_month'),
                current_plan: t('current_plan'),
                billed_monthly: t('billed_monthly'),
                billed_yearly: t('billed_yearly'),
                per_month_short: t('per_month_short'),
              }}
            />
          </SubtitledContentBlockWithCta>
        </Cols>
      </div>
    </div>
  )
}
