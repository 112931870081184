import * as React from 'react'
import useEmblaCarousel, {
  type EmblaCarouselType as CarouselApi,
  type EmblaOptionsType as CarouselOptions,
  type EmblaPluginType as CarouselPlugin,
} from 'embla-carousel-react'
import { AnimatePresence, motion } from 'framer-motion'
import { cn } from '~/utils/misc.ts'
import { Icon } from '~/components/icon.tsx'

type CarouselProps = {
  opts?: CarouselOptions
  plugins?: CarouselPlugin[]
  orientation?: 'horizontal' | 'vertical'
  setApi?: (api: CarouselApi) => void
}
type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0]
  api: ReturnType<typeof useEmblaCarousel>[1]
  scrollPrev: () => void
  scrollNext: () => void
  canScrollPrev: boolean
  canScrollNext: boolean
} & CarouselProps
const CarouselContext = React.createContext<CarouselContextProps | null>(null)
const buttonVariants = {
  hidden: {
    opacity: 0,
    transition: { duration: 0.2 }, // Faster duration for hiding
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.5 }, // Slower duration for showing
  },
}
function useCarousel() {
  const context = React.useContext(CarouselContext)
  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />')
  }
  return context
}
const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(({ opts, setApi, plugins, className, children, ...props }, ref) => {
  const [carouselRef, api] = useEmblaCarousel(
    {
      ...opts,
      axis: 'x',
    },
    plugins,
  )
  const [canScrollPrev, setCanScrollPrev] = React.useState(false)
  const [canScrollNext, setCanScrollNext] = React.useState(false)
  const onSelect = React.useCallback((api: CarouselApi) => {
    if (!api) {
      return
    }
    setCanScrollPrev(api.canScrollPrev())
    setCanScrollNext(api.canScrollNext())
  }, [])
  const scrollPrev = React.useCallback(() => {
    api?.scrollPrev()
  }, [api])
  const scrollNext = React.useCallback(() => {
    api?.scrollNext()
  }, [api])
  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'ArrowLeft') {
        event.preventDefault()
        scrollPrev()
      } else if (event.key === 'ArrowRight') {
        event.preventDefault()
        scrollNext()
      }
    },
    [scrollPrev, scrollNext],
  )
  React.useEffect(() => {
    if (!api || !setApi) {
      return
    }
    setApi(api)
  }, [api, setApi])
  React.useEffect(() => {
    if (!api) {
      return
    }
    onSelect(api)
    api.on('reInit', onSelect)
    api.on('select', onSelect)
    return () => {
      api?.off('select', onSelect)
    }
  }, [api, onSelect])
  return (
    <CarouselContext.Provider
      value={{
        carouselRef,
        api: api,
        opts,
        orientation: 'horizontal',
        scrollPrev,
        scrollNext,
        canScrollPrev,
        canScrollNext,
      }}
    >
      <div
        ref={ref}
        onKeyDownCapture={handleKeyDown}
        className={cn('relative', className)}
        aria-roledescription="carousel"
        {...props}
      >
        {children}
      </div>
    </CarouselContext.Provider>
  )
})
Carousel.displayName = 'Carousel'
const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel()
  const { children } = props

  return (
    <div ref={carouselRef} className="overflow-hidden">
      <div
        ref={ref}
        className={cn(
          'flex',
          orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col',
          className,
        )}
      >
        <CarouselItem className="basis-[calc((100%-80rem)/2)] " />
        {children}
      </div>
    </div>
  )
})
CarouselContent.displayName = 'CarouselContent'
const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { orientation } = useCarousel()
  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn(
        'min-w-0 shrink-0 grow-0 basis-full',
        orientation === 'horizontal' ? 'pl-4' : 'pt-4',
        className,
      )}
      {...props}
    />
  )
})
CarouselItem.displayName = 'CarouselItem'
const CarouselPrevious = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, ...props }, ref) => {
  const { scrollPrev, canScrollPrev } = useCarousel()
  return (
    <AnimatePresence>
      {canScrollPrev && (
        <motion.div
          initial={false}
          animate="visible"
          exit="hidden"
          variants={buttonVariants}
          transition={{ duration: 0.5 }} // Customize duration and other transition properties
        >
          <button
            ref={ref}
            className={cn(
              'absolute left-12 top-1/2 hidden h-16 w-16 -translate-y-1/2 rotate-180 justify-center rounded-full bg-global-white p-0 opacity-30 shadow-2xl hover:opacity-100 md:block',
              className,
            )}
            onClick={scrollPrev}
            {...props}
          >
            <Icon name="chevron-right" className="h-10 w-10" />
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  )
})
CarouselPrevious.displayName = 'CarouselPrevious'
const CarouselNext = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, ...props }, ref) => {
  const { scrollNext, canScrollNext } = useCarousel()
  return (
    <AnimatePresence>
      {canScrollNext && (
        <motion.div
          initial={false}
          animate="visible"
          exit="hidden"
          variants={buttonVariants}
          transition={{ duration: 0.5 }} // Customize duration and other transition properties
        >
          <button
            ref={ref}
            className={cn(
              'absolute right-12 top-1/2 hidden h-16 w-16 -translate-y-1/2 justify-center rounded-full bg-global-white p-0 opacity-30 shadow-2xl hover:opacity-100 md:block',
              className,
            )}
            onClick={scrollNext}
            {...props}
          >
            <Icon name="chevron-right" className="h-10 w-10" />
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  )
})
CarouselNext.displayName = 'CarouselNext'
export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
}
