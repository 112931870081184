import { axisBottom, axisLeft, scaleLinear, scaleQuantize } from 'd3'
import { select } from 'd3-selection'
import { intervalToDuration } from 'date-fns'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { WorkoutSegmentFragment } from '~/model/api.ts'
import { theme } from '@rouvydev/web-components/utils'
import { useChartDimensions } from '~/utils/chart-dimentsion.ts'

interface WorkoutIntervalProps {
  segments: WorkoutSegmentFragment[]
  isSmall?: boolean
}

type Severity = 'easy' | 'normal' | 'hard'

export function WorkoutInterval({
  segments,
  isSmall = false,
}: WorkoutIntervalProps) {
  const chartSettings = {
    marginTop: isSmall ? 2 : 50,
    marginBottom: isSmall ? 0 : 50,
    marginRight: isSmall ? 0 : 58,
    marginLeft: isSmall ? 0 : 58,
  }

  const { t } = useTranslation(['activity'])
  const [ref, dms] = useChartDimensions(chartSettings)
  const graphHeight = isSmall ? 128 : 200

  const colors = useMemo(
    () =>
      ({
        easy: '#9DDF46', // not presented in theme
        normal: theme.colors['border-accent'],
        hard: theme.colors['background-negative'],
      }) as Record<Severity, string>,
    [],
  )

  const formattedTime = (seconds: number) => {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
    return `${duration.hours?.toString().padStart(2, '0')}:${duration.minutes
      ?.toString()
      .padStart(2, '0')}`
  }

  useEffect(() => {
    const ftpMin = Math.min(...segments.map(i => i.value))
    const ftpMax = Math.max(...segments.map(i => i.value))
    const totalTime = segments
      .map(i => i.duration)
      .reduce((acc, i) => acc + i, 0)

    const scaleY = scaleLinear().domain([ftpMax, 0]).range([0, graphHeight])
    const scaleYInverse = scaleLinear()
      .domain([0, ftpMax])
      .range([0, graphHeight])
    const axisFtp = axisLeft(scaleY).ticks(4).tickSize(-dms.boundedWidth!)

    const scaleSeverity = scaleQuantize<Severity>()
      .domain([ftpMin, ftpMax])
      .range(['easy', 'normal', 'hard'])

    const scaleX = scaleLinear()
      .domain([0, totalTime])
      .range([0, dms.boundedWidth!])

    const timeTicks = dms.boundedWidth! > 700 ? 8 : 4
    const axisTime = axisBottom(scaleX)
      .ticks(timeTicks)
      .tickFormat(value => formattedTime(+value))

    select('#axis-ftp').call(axisFtp as any)
    select('#axis-time').call(axisTime as any)

    scaleLinear().domain([0, ftpMax]).range([0, 100])

    const bars = select(ref.current!).select('.bars')
    bars.selectAll('*').remove()

    let positionX = 0
    segments.forEach(segment => {
      const height = scaleYInverse(segment.value)
      const width = scaleX(segment.duration)

      const severity = scaleSeverity(segment.value)
      bars
        .append('rect')
        .attr('x', positionX)
        .attr('y', graphHeight - height)
        .attr('width', width)
        .attr('height', height)
        .attr('fill', `url(#${isSmall ? 'isSmall' : severity})`)

      bars
        .append('line')
        .attr('x1', positionX)
        .attr('y1', graphHeight - height)
        .attr('x2', width + positionX)
        .attr('y2', graphHeight - height)
        .attr('stroke', colors[severity])
        .attr('stroke-width', isSmall ? 5 : 3)
        .attr('stroke-linecap', 'butt')

      positionX += scaleX(segment.duration)
    })
  }, [colors, dms.boundedWidth, graphHeight, isSmall, ref, segments])

  return (
    <div ref={ref} style={{ height: graphHeight }}>
      <svg width={dms?.width} height={dms.height}>
        <g
          transform={`translate(${[dms.marginLeft, dms.marginTop].join(',')})`}
          id="holder"
        >
          <g className="bars"></g>

          {!isSmall && (
            <>
              <g id="axis-time" transform="translate(0, 200)"></g>
              <g id="axis-ftp"></g>

              <text
                transform="translate(-20, -24)"
                fill="#B1AFC2"
                fontSize="10"
              >
                {t('activity::ftp')}
              </text>

              <text
                transform={`translate(${dms.width! - 100}, 215)`}
                fill="#B1AFC2"
                fontSize="10"
              >
                {t('activity::time')}
              </text>
            </>
          )}
        </g>

        <defs>
          <linearGradient id="hard" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor={colors.hard} stopOpacity="50%" />
            <stop offset="100%" stopColor={colors.hard} stopOpacity="0" />
          </linearGradient>

          <linearGradient id="easy" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor={colors.easy} stopOpacity="50%" />
            <stop offset="100%" stopColor={colors.easy} stopOpacity="0" />
          </linearGradient>

          <linearGradient id="isSmall" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#6F10E7" stopOpacity="60%" />
            <stop offset="100%" stopColor="#6F10E7" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export default WorkoutInterval
